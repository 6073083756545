











































































































































































































.classRecord {
  .formstyle {
    overflow: hidden;
    .form-inline {
      width: 50%;
      float: left;
      margin-bottom: 0.625rem;
    }
  }
}
